import React from 'react'
import Layout from '../components/layout'
import logo from '../images/Lancelot PianoB.png'

const Success = () => {
  return (
    <Layout logo={logo} color="#333" menu="Accueil" lien="/">
      <h2 style={{ textAlign: 'center', marginTop: '6rem' }}>
        Merci de nous avoir contacté !
      </h2>
      <h3 style={{ textAlign: 'center', marginTop: '6rem' }}>
        Votre message est bien envoyé, nous ne manquerons pas de vous
        recontacter rapidement.
      </h3>
    </Layout>
  )
}

export default Success
